<template>
  <div class="videoPageWrap">
    <!-- 精彩视频 -->
    <m-layout-bar>
      <div class="videoWrap" :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}">
        <div class="content shadowBox">
          <div class="titleWrap">
            <img class="title" :src="require('../assets/image/video/title.png')" alt />
            <div class="btn ifocus_right" @click="clickHandle">
              <img :src="require('../assets/image/video/btn.png')" alt />
            </div>
          </div>

          <!--视频 列表 -->
          <!--给每一行的最后一个item设置margin=0 :class="{'marginRightZero':(index+1)%3==0}" -->
          <div
            class="listWrap"
            :class="{'marginRightZero':(index+1)%3==0}"
            v-for="(item,index) in dataInfo"
            :key="index"
          >
            <!-- 单个视频 -->
            <div class="item shadowBox_center ifocus_up">
              <div
                class="videoWrap"
                @click="showMask(item.title,item.link)"
                :data-name="item.title"
              >
                <img class="itemPic" :src="baseUrl + item.cover" alt />
                <img class="iconPlay" :src="require('../assets/image/video/play_icon.png')" alt />
                <div class="countWrap">
                  <img class="iconCount" :src="require('../assets/image/video/count.png')" alt />
                  <span class="total">{{item.watch_num}}</span>
                </div>
              </div>
              <div class="desc">
                <span class="title">{{item.title}}</span>
                <span class="subtitle">{{item.info}}</span>
                <span class="time">{{item.time}}</span>
              </div>
            </div>
          </div>

          <div class="pagWrap">
            <Pagination :total="total" :perPageSize="9" @change="changePagination"></Pagination>
          </div>
        </div>
      </div>
    </m-layout-bar>
    <!-- 遮罩 -->
    <div class="iMask" v-show="showFlag">
      <div class="maskWrap">
        <div class="closeWrap">
          <img
            class="close"
            @click="closeMask"
            :src="require('../assets/image/video/close.png')"
            alt
          />
        </div>
        <div class="titleWrap">
          <span class="title">{{videoPlayTitle}}</span>
          <img class="logo" :src="require('../assets/image/video/v_logo.png')" alt />
        </div>
        <!-- video标签嵌入视频各个浏览器通用 -->
        <video
          class="code"
          :src="videoPlayUrl"
          width="1400"
          height="713"
          controls
          autoplay="autoplay"
          ref="m_video"
        ></video>
        <!-- <video class="code" width="1400" height="713" controls autoplay="autoplay" ref="m_video">
          <source :src="require('../assets/video/movie.mp4')" type="video/mp4" />
        </video>-->
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
import Pagination from "@/components/pagination";

import axios from "axios";
import config from "@/utils/config";
import { checkIsNumber } from "@/utils/util.js";

export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    Pagination
  },
  data() {
    return {
      bgImgUrl: require("../assets/image/video/bg.png"),
      videoList: new Array(7),
      showFlag: false,
      total: 180, // 必传传递参数total
      current: 1,
      dataInfo: new Array(),
      // clickItemTitle: "",
      videoPlayUrl: "",
      videoPlayTitle: ""
    };
  },
  mounted() {
    this.getData(config.videoUrl);
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  methods: {
    changePagination(cur) {
      this.current = cur;
      let url = config.vrUrl.split("=")[0] + "=" + cur;
      // console.log("url:", url, cur);
      this.getData(url);
    },
    setPageBarParams(count) {
      if (count != null) {
        if (count == "") {
          this.total = 0;
        } else {
          let flag = checkIsNumber(count);
          if (flag) {
            this.total = count;
          }
        }
      }
    },
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          this.setPageBarParams(res.data.count);
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
          }
          // console.log("res.data.data:", this.total, this.dataInfo);
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    showMask(title, link) {
      this.videoPlayUrl = this.baseUrl + link;
      this.videoPlayTitle = title;
      this.showFlag = true;
    },
    closeMask() {
      this.showFlag = false;
      // this.clickItemTitle = "";
      this.videoPlayUrl = "";
      this.videoPlayTitle = "";
      this.$refs.m_video.pause();
    },
    clickHandle() {
      this.$router.push({ path: "/panorama" });
    }
  }
};
</script>


<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.videoPageWrap {
  height: 100%;
}
.marginRightZero {
  margin-right: 0 !important;
}

.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  text-align: right;
  z-index: 99999;
  .maskWrap {
    position: relative;
    position: absolute;
    // width:320px;
    // height:240px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > .closeWrap {
      .close {
        cursor: pointer;
      }
    }
    .titleWrap {
      position: relative;
      height: 80px;
      line-height: 80px;
      font-size: 26px;
      font-weight: bold;
      overflow: hidden;
      background: red;
      text-align: left;
      color: $main-white;
      padding-left: 27px;
      background: #000;
      overflow: hidden;
      .logo {
        position: absolute;
        top: 10px;
        right: 27px;
        height: 60px;
        vertical-align: middle;
      }
    }
  }
  .code {
    display: block;
    margin-bottom: 10px;
    cursor: default;
  }
}
// .iMask {
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   background: rgba(0, 0, 0, 0.7);
//   text-align: center;
//   .video {
//     position: absolute;
//     display: block;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     margin: auto;
//   }
//   .close {
//     position: absolute;
//     top: 10;
//     right: 10;
//     color: $main-white;
//     font-size: 40px;
//     font-weight: bold;
//     cursor: pointer;
//   }
// }

.videoWrap {
  // border: 1px solid transparent;
  // .bg {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   width: 100%;
  //   height: 100%;
  // }
  // background:blue;
  .content {
    width: 1400px;
    margin: 82px auto 50px auto;
    border: 1px solid $main-gray;
    padding: 19px 47px 50px 47px;
    margin-bottom: 70px;
    background: $main-white;
    .titleWrap {
      position: relative;
      .btn {
        // text-align:right;
        position: absolute;
        display: inline-block;
        right: 0;
        bottom: 10px;
      }
    }
    .listWrap {
      width: 407px;
      display: inline-block;
      margin-right: 80px;
      border: 1px solid transparent;
      box-sizing: border-box;
      > .item {
        width: 407px;
        height: 340px;
        margin-top: 47px;
        border-radius: 10px;
        cursor: pointer;
        overflow: hidden;
        > .videoWrap {
          position: relative;
          width: 407px;
          height: 230px;
          overflow: hidden;
          .itemPic {
            width: 100%;
            height: 228px;
            border-radius: 10px;
          }
          .iconPlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .countWrap {
            position: absolute;
            width: 122px;
            height: 31px;
            line-height: 31px;
            text-align: right;
            padding-right: 17px;
            top: 0;
            right: 0;
            color: $main-white;
            font-size: 12px;
            border-top-right-radius: 10px;
            background-image: linear-gradient(
              to right,
              transparent,
              rgb(112, 111, 111)
            );
            > .iconCount {
              margin-right: 5px;
              vertical-align: middle;
            }
          }
          // background:red;
        }
        .desc {
          position: relative;
          padding-top: 16px;
          padding-left: 10px;
          padding: 16px 0px 15px 10px;
          > .title,
          > .subtitle {
            display: block;
          }
          > .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          > .subtitle {
            width: 407px;
            height: 44px; //高度限制
            font-size: 13px;
            // overflow:hidden;
          }
          > .time {
            position: absolute;
            width: 121px;
            height: 31px;
            top: -26px;
            left: 10px;
            font-size: 12px;
            color: $main-white;
          }
        }
      }
    }
    .listWrap:last-of-type {
      margin-right: 0;
    }
    > .pagWrap {
      width: 400px;
      padding-top: 20px;
      margin: 0 auto;
      text-align: center;
    }
  }
}
@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .videoWrap {
    .content {
      .titleWrap {
        position: relative;
        .btn {
          right: 50%;
        }
      }
    }
  }
}
@media (max-width: $screen-sp-min) {
  .videoWrap {
    .content {
      .titleWrap {
        position: relative;
        .btn {
          right: 20%;
        }
      }
    }
  }
}
</style>
